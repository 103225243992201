import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";
import HeroImage from "../data/images/hero-contact.jpg"
import BackgroundImageSelf from "../components/background-image-self";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
export const query = graphql`
  query {
    allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
}
}

`



const Contact = ({data}) => {
    const WebsiteContent = data.allDataJson.edges[0].node
    return(
        <main>
            <Helmet>
                <title>{WebsiteContent.Business_Name + " | Contact Us"}</title>
                <meta name="description" content={WebsiteContent.Tag_Line} />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImageSelf image={HeroImage} mobileImage={HeroImage} alt={"forest background"} height={"h-128"}  hero={true}>
                <h1 className={"text-8xl text-center text-white p-6"}>Contact Us</h1>
            </BackgroundImageSelf>
            <ContactForm/>
            <Footer/>
        </main>
    )
}

export default Contact